import * as React from "react"

const Founder = () => {
    return (
      <>
      <hr></hr>
      <header> <h1>创始团队</h1> </header>
      <div style={{'display': 'flex', 'justifyContent': 'center', 'flexWrap': 'wrap'}}>

        <div className="card" >
          <div className="card-body">
            <h5 className="card-title">薛亮</h5>
            <h6 className="card-subtitle mb-2 text-muted">Founder</h6>
            <p> <small>2011年建立面包旅行，C轮获得腾讯领投的5000万美金</small> </p>
          <p> <small>2017年孵化城宿，后于2019年以数亿价格出售</small> </p>
          <p><small>多次将公司从0打造至数百人团队</small></p>
          <p> <small>多次从0开始建立品牌和产品，曾获得苹果中国区Top10</small> </p>       
            
          </div>
        </div>

        <div className="card mt-3" >
          <div className="card-body">
            <h5 className="card-title">陈之光</h5>
            <h6 className="card-subtitle mb-2 text-muted">Co-Founder</h6>
            <p><small>10+工程师经验，多年技术团队管理经验</small></p>
          <p><small>多次创业公司经验，能胜任从0开始的全部阶段过程</small></p>
          <p><small>常年在技术社群保持活跃，对目标人群有感</small></p>   
            
          </div>
        </div>
        

      </div>
      
      </>
  
    )
  }
  
  export default Founder
  
import * as React from "react"

const Demo = () => {
    return (
      <>
      <hr></hr>
    <header>
      <h1>Demo</h1> 
      <small>图片为用户访谈时的demo项目;视频则分别录制于22年的3月/2月/1月</small>
    </header>
    <figure> <img src="http://images.xidebaby.com.cn/LQYotSKe0ds2fPQxGWabKhjp0ylUGuyq/demo1.png" alt="Funding" /> </figure>    
    <figure> <img src="http://images.xidebaby.com.cn/cGRm82O9EQdG0t6njDpNVefX3WC6EGJ5/demo2.png" alt="Funding" /> </figure>    
    <figure> <img src="http://images.xidebaby.com.cn/q7qzffRCAL4eAOV0BW4d6UvNb9R9NYox/demo3.png" alt="Funding" /> </figure>    
    <figure> <img src="http://images.xidebaby.com.cn/fexcxRX7buOSsSIA9DIWbRD98hUGIymQ/demo4.png" alt="Funding" /> </figure>    
    <figure> <img src="http://images.xidebaby.com.cn/AC3vYT6EOHWaVpcYSEAhCfiKfdush99i/demo5.png" alt="Funding" /> </figure>    
    <figure> <img src="http://images.xidebaby.com.cn/jUOVsd6OWWvDds12RfwQCwhBqkzX5qJI/demo6.png" alt="Funding" /> </figure>    
    

    <figure>
      <video src="http://nbimgs.breadtrip.com/C0GqaxCJAij28w3xSVRgMIakNbhMvJeF/demo-20220306.MOV" 
             poster="http://nbimgs.breadtrip.com/bppOv4lMbFLMRjFsh8dJJ0Efix9XIdtX/cover.jpg" 
             controls className="w-100"/>
    </figure>
    <figure>
      <video src="http://nbimgs.breadtrip.com/Ol0A5kHcV8KYYjNiiXVgUbe6Ooake8t7/compayrocks%20-%2026%20January%202022.mov" 
             controls className="w-100"/>
    </figure>
    <figure>
      <video src="http://nbimgs.breadtrip.com/TeLfeKzsc7YF2csbn9VNFAEy6VA5lSnV/3rd%20Demo%20-%2029%20January%202022.mp4" 
             controls className="w-100"/>
    </figure>
      
      </>
  
    )
  }
  
  export default Demo
  
import * as React from "react"
import Demo from "../components/Demo"
import Founder from "../components/Founder"
import RoadMap from "../components/RoadMap"
// import fundingImage from "../images/funding.jpeg"
// import endingImage from "../images/ending.jpeg"
// import roadMapImage from "../images/roadmap.jpeg"
// import marketImage from "../images/market.jpeg"
import logo from "../images/logo.png"
// import 'mvp.css'
import './board9.cn.css'

// styles
const pageStyles = {
  // color: "#232129",
  padding: 35,
  // fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const logoStyles = {
  width: "100px"
}
// data


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles} id="app">
      <h1>本BP已过期，如需了解近况请微信联系 :)</h1>
    </main>
  )
}

export default IndexPage

import * as React from "react"

const RoadMap = () => {
    return (
      <>
            <hr></hr>
      <header> <h1>RoadMap</h1> <p>本轮融资用于10-20人团队12-18个月的工作</p> </header>

      <sup>0~6个月</sup>
    <ul>
      <li>产品</li>
      <p>和天使客户合作，完成包括20+内置组件的基本功能</p>
      <li>市场</li>
      <p>通过自媒体的议程设置和文化输出获取早期客户</p>
    </ul>
    
    <sup className="mt-5">6~12个月</sup>
    <ul>
      <li>产品</li>
      <p>70+控件/10+数据库支持</p>
      <li>客户</li>
      <p>首次开始交付中型客户（举例：蓝湖）</p>
      <p>达成1000家付费客户</p>
      <li>市场</li>
      <p>积极参与技术社群/开源的宣传和推广</p>      
      <li>融资</li>
      <p>开始下一轮融资</p>
    </ul>
    
    <sup className="mt-5">12~18个月</sup>
    <ul>
      <li>产品</li>
      <p>搭建产品矩阵（举例：sql9)</p>
      <li>客户</li>
      <p>开始交付大型客户（举例：Airbnb）</p>
      <li>融资</li>
      <p>完成下一轮融资</p>
    </ul>
      </>
  
    )
  }
  
  export default RoadMap
  